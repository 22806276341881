//const baseUrl = "http://localhost:5000";
const baseUrl = "https://electric-blue-katydid-vest.cyclic.app/";
const cloud = "https://res.cloudinary.com/dhusvnwal/image/upload/"

export const api = `${baseUrl}/api`;

export const generatePublicUrl = (fileName) => {
  return `${baseUrl}/public/${fileName}`;
};
export const generateUrl = (fileName) => {
  return `${baseUrl}${fileName}`;
};
export const generateCloud = (fileName)=>{
  return `${cloud}${fileName}`;


}





