

import INFO1 from '../../images/New folder/افتتاحية.jpg'
import INFO2 from '../../images/New folder/التعريف.jpg'
import INFO3 from '../../images/New folder/اهداف.jpg'
import INFO4 from '../../images/New folder/مرحلة 1.jpg'
import INFO5 from '../../images/New folder/مرحلة 2.jpg'
import INFO6 from '../../images/New folder/المرحلة 3 (1).jpg'
import INFO7 from '../../images/New folder/مميزات.jpg'


import audioUrl from "../../images/voic/افتتاحية البرنامج.mp3"
import audioUrl1 from "../../images/voic/الأهداف.mp3"
import audioUrl2 from "../../images/voic/التعريف بالبرنامج.mp3"
import audioUrl3 from "../../images/voic/المرحلة الأولى.mp3"
import audioUrl4 from "../../images/voic/المرحلة الثانية.mp3"
import audioUrl5 from "../../images/voic/المرحلة الثالثة.mp3"
import audioUrl7 from "../../images/voic/مميزات البرنامج.mp3"

const data = [


      {
         
        id:1,
        image:INFO1,
        audioUrl: audioUrl,
        text:"افتتاحية البرنامج"
        
      },


        {
            
            id:2,

          image:INFO2,
          audioUrl: audioUrl2,
          text:"التعريف بالبرنامج"
          
        },
        {
            id:3,

          image:INFO3,
          audioUrl: audioUrl1,
          text:"أهداف البرنامج."          
        },
        {
          id:4,
          image:INFO4,
          audioUrl: audioUrl3,
          text:"تفاصيل المرحلة الأولى  "
          
        },
        {
            id:5,

          image:INFO5,
          audioUrl: audioUrl4,
          text:"تفاصيل المرحلة الثانية "
          
        },
        {
            id:6,

          image:INFO6,
          audioUrl: audioUrl5,
          text:"تفاصيل المرحلة الثالثة "
          
        },
        {
            id:7,

            image:INFO7,
            audioUrl: audioUrl7,
            text:"مميزات البرنامج "
            
          },
   ]

   export {data}