export const categoryConstansts = {
    GET_ALL_CATEGORIES_REQUEST: "GET_ALL_CATEGORIES_REQUEST",
    GET_ALL_CATEGORIES_SUCCESS: "GET_ALL_CATEGORIES_SUCCESS",
    GET_ALL_CATEGORIES_FAILURE: "GET_ALL_CATEGORIES_FAILURE",
    ADD_NEW_CATEGORY_REQUEST: "ADD_NEW_CATEGORY_REQUEST",
    ADD_NEW_CATEGORY_SUCCESS: "ADD_NEW_CATEGORY_SUCCESS",
    ADD_NEW_CATEGORY_FAILURE: "ADD_NEW_CATEGORY_FAILURE",
  };
  export const Teachers ={
    GET_ALL_TEACHERS : "GET_ALL_TEACHERS"
  }
  
  export const productConstants = {
    GET_PRODUCTS_BY_SLUG: "GET_PRODUCTS_BY_SLUG",
    GET_PRODUCT_PAGE_REQUEST: "GET_PRODUCT_PAGE_REQUEST",
    GET_PRODUCT_BY_SEARCH: "GET_PRODUCT_BY_SEARCH",
    GET_ALL_PRODUCTS: "GET_ALL_PRODUCTS",
    GET_ALL_PAGES: "GET_ALL_PAGES",
    GET_PRODUCT_PAGE_SUCCESS: "GET_PRODUCT_PAGE_SUCCESS",
    GET_PRODUCT_PAGE_FAILURE: "GET_PRODUCT_PAGE_FAILURE",
  
    GET_PRODUCT_DETAILS_BY_ID_REQUEST: "GET_PRODUCT_DETAILS_BY_ID_REQUEST",
    GET_PRODUCT_DETAILS_BY_ID_SUCCESS: "GET_PRODUCT_DETAILS_BY_ID_SUCCESS",
    GET_PRODUCT_DETAILS_BY_ID_FAILURE: "GET_PRODUCT_DETAILS_BY_ID_FAILURE",
  };
  export const PostConstants = {
    GET_ALL_POSTS: "GET_ALL_POSTS",
    GET_ALL_POSTS_SUCCESS : "GET_ALL_POSTS_SUCCESS" ,
    GET_ALL_POSTS_FAILED : "GET_ALL_POSTS_FAILED",
    GET_ALL_POSTS_FAILED : "GET_ALL_POSTS_FAILED",
    CREATE_POSTS: "CREATE_POSTS",
    CREATE_POSTS_SUCCESS: "CREATE_POSTS_SUCCESS",
    CREATE_POSTS_FAILED: "CREATE_POSTS_FAILED",
    DELETE_POSTS: "DELETE_POSTS",
    DELETE_POSTS_SUCCESS :"DELETE_POSTS_SUCCESS",
    DELETE_POSTS_FAILED :"DELETE_POSTS_FAILED",
    CREATE_POSTS_REQUEST:"CREATE_POSTS_REQUEST"
  };
  export const GroupsConstants = {
    GET_ALL_GROUPS: "GET_ALL_GROUPS",
    GET_ALL_GROUPS_SUCCESS : "GET_ALL_GROUPS_SUCCESS" ,
    GET_ALL_GROUPS_FAILED : "GET_ALL_GROUPS_FAILED",
    GET_ALL_GROUPS_FAILED : "GET_ALL_GROUPS_FAILED",

  };
  export const OrderConstants ={
    CREATE_ORDER: "CREATE_ORDER",
    CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
    CREATE_ORDER_FAILED: "CREATE_ORDER_FAILED",

  }

  
  export const authConstants = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAILURE: "LOGOUT_FAILURE",
  
    SIGNUP_REQUEST: "SIGNUP_REQUEST",
    SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
    SIGNUP_FAILURE: "SIGNUP_FAILURE",
  };
  
  export const cartConstants = {
    ADD_TO_CART_REQUEST: "ADD_TO_CART_REQUEST",
    ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
    ADD_TO_CART_FAILURE: "ADD_TO_CART_FAILURE",
    RESET_CART: "RESET_CART",
  
    REMOVE_CART_ITEM_REQUEST: "REMOVE_CART_ITEM_REQUEST",
    REMOVE_CART_ITEM_SUCCESS: "REMOVE_CART_ITEM_SUCCESS",
    REMOVE_CART_ITEM_FAILURE: "REMOVE_CART_ITEM_FAILURE",
  };
  
  export const userConstants = {
    GET_USER_ADDRESS_REQUEST: "GET_USER_ADDRESS_REQUEST",
    GET_USER_ADDRESS_SUCCESS: "GET_USER_ADDRESS_SUCCESS",
    GET_USER_ADDRESS_FAILURE: "GET_USER_ADDRESS_FAILURE",
    ADD_USER_ADDRESS_REQUEST: "ADD_USER_ADDRESS_REQUEST",
    ADD_USER_ADDRESS_SUCCESS: "ADD_USER_ADDRESS_SUCCESS",
    ADD_USER_ADDRESS_FAILURE: "ADD_USER_ADDRESS_FAILURE",
    ADD_USER_ORDER_REQUEST: "ADD_USER_ORDER_REQUEST",
    ADD_USER_ORDER_SUCCESS: "ADD_USER_ORDER_SUCCESS",
    ADD_USER_ORDER_FAILURE: "ADD_USER_ORDER_FAILURE",
  
    GET_USER_ORDER_REQUEST: "GET_USER_ORDER_REQUEST",
    GET_USER_ORDER_SUCCESS: "GET_USER_ORDER_SUCCESS",
    GET_USER_ORDER_FAILURE: "GET_USER_ORDER_FAILURE",
  
    GET_USER_ORDER_DETAILS_REQUEST: "GET_USER_ORDER_DETAILS_REQUEST",
    GET_USER_ORDER_DETAILS_SUCCESS: "GET_USER_ORDER_DETAILS_SUCCESS",
    GET_USER_ORDER_DETAILS_FAILURE: "GET_USER_ORDER_DETAILS_FAILURE",
  };