import React, { useEffect ,useState } from "react";
import {BrowserRouter ,Routes, Route } from 'react-router-dom';
import Navbar from './Components/NavBar/Navbar.jsx';
import ScrollToTop  from './Components/ScrollToTop'
//import './course.css'
import './style.css'
//import './normalize.css'
import Registration from './containers/Registration/Registration.jsx'
import Success from "./Components/Success/Success.jsx";
//import Main from './Components/main/main.jsx'


const app = () => {




  return (
    <BrowserRouter>
    <ScrollToTop />

    <Routes>
    <Route path='/' element={<Registration />} />
   <Route path='/success' element={<Success />} />
   { /* <Route path='/courses' element={<Courses />} />
    <Route path='/contact' element={<Login />} />
    <Route path='/registration/:slug' element={<Registration />} />*/}



    </Routes>
    </BrowserRouter>

  )
}

export default app