import React, { useState } from "react";
import FileBase from "react-file-base64";
import "./styel/Registration.scss";
import "./styel/registration.css";
import logo from "../../images/mahir.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import INFO2 from "../../images/infNew2.jpg";
import INFO1 from "../../images/infoNex1.jpg";
import ccp from '../../images/ayoub.jpg'
import ccp1 from '../../images/ayoub.jpg'
import { BsFillCloudUploadFill } from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
import { data } from "./Data";
import { AiFillPlayCircle, AiFillPauseCircle } from "react-icons/ai";
import INFO from "../../images/New folder/الواجهة.jpg";
import { useDispatch } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { CreateOrder } from "../../actions/order";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 1200, min: 1000 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 720 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 720, min: 500 },
    items: 1,
  },
  SmallMobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1,
  },
};

const Registration = ({ alert }) => {
  const [fullName, setFullName] = useState(" ");
  const [email, setEmail] = useState(" ");
  const [location, setLocation] = useState(" ");
  const [Birthday, setBirthday] = useState("2023-01-01");
  const [phoneNumber, setPhonNumber] = useState(null);
  const [Hifeth, setHifeth] = useState("");
  const [Style, setStyle] = useState("");
  const [paymentPicture, setPaymentPicture] = useState([]);
  const navigate = useNavigate();
  const [currentAudio, setCurrentAudio] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);

  const handleAudioClick = (id) => {
    const audioEl = document.getElementById(`audio-${id}`);
    if (audioEl === currentAudio) {
      if (audioEl.paused) {
        audioEl.play();
        setIsPlaying(true);
      } else {
        audioEl.pause();
        setIsPlaying(false);
      }
    } else {
      if (currentAudio) {
        currentAudio.pause();
        setIsPlaying(false);
      }
      audioEl.play();
      setIsPlaying(true);
      setCurrentAudio(audioEl);
      setCurrentTime(audioEl.currentTime);
      setDuration(audioEl.duration);
    }
  };

  const handleTimeUpdate = (e) => {
    setCurrentTime(e.target.currentTime);
  };

  const handleDurationChange = (e) => {
    setDuration(e.target.duration);
  };

  const handleProgressBarClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const progressBarWidth = rect.right - rect.left;
    const clickedTime = (x / progressBarWidth) * duration;
    if (currentAudio) {
      currentAudio.currentTime = clickedTime;
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };



  const onSubmit = () => {
    const form = {
      fullName,
      email,
      phoneNumber,
      GroupID: "46654654984654314",
      location,
      Birthday,
      paymentPicture,
      Style,
      Hifeth,
    };

    if (fullName === " ") {
      toast.error("الرجاء إدخال الإسم كاملا", {
        position: "top-center",
        direction: "rtl",
      });

      return;
    } else if (email === " ") {
      toast.error("الرجاء إدخال البريد الإلكتروني", {
        position: "top-center",
      });

      return;
    }
    if (phoneNumber === null) {
      toast.error("الرجاء إدخال رقم الهاتف", {
        position: "top-center",
      });

      return;
    }
    if (paymentPicture.length === 0) {
      toast.error("الرجاء ادخال صورة الوصل", {
        position: "top-center",
      });

      return;
    } if (Hifeth === " ") {
      toast.error("الرجاء كتابة مقدار حفظك ", {
        position: "top-center",
      });

      return;
    } if (Style === " ") {
      toast.error("الرجاء كتابة طريقة التي تريد ان تحفظ بها ", {
        position: "top-center",
      });

      return;
    } else {
      toast.success("جاري إرسال الطلب", {
        position: "top-center",
      });
      dispatch(CreateOrder(form))
        .then(() => {
          navigate("/success");
          setFullName("");
          setPhonNumber("");
          setEmail("");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const dispatch = useDispatch();
  const customLeftArrow = (
    <div className="absolute arrow-btn left-0 text-center py-3 cursor-pointer bg-pink-600 rounded-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-white w-full"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 19l-7-7m0 0l7-7m-7 7h18"
        />
      </svg>
    </div>
  );

  const customRightArrow = (
    <div className="absolute arrow-btn right-0 text-center py-3 cursor-pointer bg-pink-600 rounded-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-white w-full"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 5l7 7m0 0l-7 7m7-7H3"
        />
      </svg>
    </div>
  );

  return (
    <div>
      
      <h2 className="main-title"> برنامج الماهر الصغير</h2>

      <div className="data-list">
        <div className="data-item">
          <div className="image-container">
            <img src={INFO} alt={"item.text"} />
            <div className="overlay" />
          </div>
          <h2>برنامج الماهر الصغير</h2>
        </div>
        {data.map((item) => (
          <div className="data-item" key={item.id}>
            <div
              className="image-container"
              onClick={() => handleAudioClick(item.id)}
            >
              <img src={item.image} alt={item.text} />
              <div className="overlay" />
            </div>
            <h2 onClick={() => handleAudioClick(item.id)}>{item.text}</h2>
            {item.audioUrl && (
              <audio id={`audio-${item.id}`}>
                <source src={item?.audioUrl} type="audio/wav" />
              </audio>
            )}
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `${(currentTime / duration) * 100}%` }}
                onClick={handleProgressBarClick}
              >
                <div
                  className="progress-bar-knob"
                  style={{ left: `${(currentTime / duration) * 100}%` }}
                />
              </div>
              <div className="time-labels">
                <span className="time-label">{formatTime(currentTime)}</span>
                <span className="time-label">{formatTime(duration)}</span>
              </div>
            </div>
            <div>
              {isPlaying ? (
                <AiFillPauseCircle onClick={() => handleAudioClick(item.id)} />
              ) : (
                <AiFillPlayCircle onClick={() => handleAudioClick(item.id)} />
              )}
            </div>
          </div>
        ))}
      </div>
      

     {
       /* 
       <h2 className="main-title"> طريقة التسجيل </h2>
     <Carousel
        autoPlay
        infinite
        customLeftArrow={customLeftArrow}
        customRightArrow={customRightArrow}
        responsive={responsive}
        itemClass="px-4"
      >
        <div className="IMAGESLIDE">
          <img src={INFO1} alt={"info"} />
          <img className="ccp" src={ccp1} alt={"ccp"} />
        </div>

        <div className="IMAGESLIDE">
          <img src={INFO2} alt={"info"} />
                    <img className="ccp" src={ccp} alt={"ccp"} />


        </div>
              </Carousel> */}
      <div className="conatct-use" >
        <ToastContainer style={{ direction: "rtl" }} />
        <h2 className="main-title"> إستمارة التسجيل </h2>
        <div className="container">
          <div className="box">
            <div className="content">
              <div className="content-title">
                <h3 className="title">
                  {" "}
                  إستمارة التسجيل في برنامج الماهر الصغير
                </h3>
              </div>
              <div className="form">
                <form action="" onSubmit={(e) => e.preventDefault()}>
                  <div className="input-box">
                    <label>إسم الطالب كاملا </label>
                    <input
                      className="input"
                      id="username"
                      type="text"
                      name="usernme"
                      placeholder="إسم المستخدم كامل"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </div>
                  <div className="input-box">
                    <label>تاريخ الميلاد</label>
                    <input
                      className="input"
                      id="birthday"
                      type="date"
                      name="birthday"
                      placeholder="تاريخ الميلاد"
                      value={Birthday}
                      onChange={(e) => setBirthday(e.target.value)}
                    />
                  </div>

                  <div className="input-box">
                    <label>مكان الإقامة</label>
                    <input
                      className="input"
                      id="username"
                      type="text"
                      name="usernme"
                      placeholder="مكان الإقامة"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                  <div className="input-box">
                    <label>رقم الهاتف</label>
                    <input
                      className="input"
                      id="username"
                      type="number"
                      name="usernme"
                      placeholder="رقم الهاتف"
                      value={phoneNumber}
                      onChange={(e) => setPhonNumber(e.target.value)}
                    />
                  </div>
                  <div className="input-box">
                    <label>البريد الإلكتروني</label>
                    <input
                      className="input"
                      id="email"
                      type="email"
                      placeholder="عنوان البريد الالكتروني"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>


                  <div className="input-box">
                    <label for="my-select">
                      الرواية التي تريد أن تحفظ بها :{" "}
                    </label>
                    <select
                      id="my-select"
                      value={Style}
                      onChange={(e) => setStyle(e.target.value)}
                    >
                      <option value="رواية ورش عن نافع من طريق الأزرق ">
                        رواية ورش عن نافع من طريق الأزرق{" "}
                      </option>
                      <option value=" رواية حفص عن عاصم">
                        {" "}
                        رواية حفص عن عاصم{" "}
                      </option>
                    </select>
                  </div>
                  <div className="input-box">
                    <label for="my-select">مقدار حفظك للقرآن حاليا </label>
                    <select
                      id="my-select"
                      value={Hifeth}
                      onChange={(e) => setHifeth(e.target.value)}
                    >
                      <option value="أكثر من 30 حزب ">أكثر من 30 حزب </option>
                      <option value="أقل من 15 حزب ">أقل من 15 حزب </option>
                      <option value="لا أحفظ شيئا">لا أحفظ شيئا</option>
                    </select>
                  </div>
                  <div className="input-box custom-file">
                    <label>صورة الوصل</label>
                    <BsFillCloudUploadFill />
                    <div className="input-box custom-file">
                      <img
                        style={{ width: "200px" }}
                        src={paymentPicture?.paymentPicture}
                      />
                    </div>
                    <FileBase
                      type="file"
                      multiple={false}
                      onDone={({ base64 }) =>
                        setPaymentPicture({
                          ...paymentPicture,
                          paymentPicture: base64,
                        })
                      }
                    />
                  </div>


                </form>
              </div>
              <div
             style={{ display: "flex", justifyContent: "center", alignItems: "center" ,marginBottom:"1rem"}}
              >
                                  <button onClick={onSubmit} className="fancy" href="#">
                     <span className="top-key"></span>
                     <span className="text">سجل الأن</span>
                     <span className="bottom-key-1"></span>
                     <span className="bottom-key-2"></span>
                   </button>


              </div>
            </div>
          </div>
          <div className="imageContainer">
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
