import React, { useEffect } from 'react'
import './Success.css'
import { BsBagCheckFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { runFireworks } from '../../utils/utils';

const Success = () => {



  useEffect(() => {
    runFireworks();
  }, []);
  return (
    <div className="success-wrapper">
    <div className="success">
      <p className="icon">
        <BsBagCheckFill />
      </p>
      <h2>
        هنيئا لك تم إستلام طلبك 
      </h2>







      
      <p className="email-msg">
 و ستتم مراجعته قريبا جدا 

رجاء تحقق من بريدك الإلكتروني ليصلك كل جديد 
كما يمكنكم التواصل معنا عبر بريدنا الإلكتروني

      </p>
      <p className="description">
      <a className="email" href="">
         academiezidniilma@gmail.com
      </a>
       اي سِؤال الرجاء ارساله هنا 
      </p>

    </div>
  </div>
  )
}

export default Success